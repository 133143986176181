import * as React from "react"
import { Link } from "gatsby"
import {Container, Wrapper, Event, EventImg, EventInfo, EventTitle, EventDate, EventPara} from "../styles/eventStyles"
import ExampleImg from "../images/image1.png"
import Layout from "../components/layout"
import Seo from "../components/seo"

const EventsPage = () => (
  <Layout>
    <Seo title="Events" />
    <Container>
        <Wrapper>
            <Event>
                <EventImg src={ExampleImg}/>
                <EventInfo>
                    <EventTitle>This is a Placeholder Title</EventTitle>
                    <EventDate>12/13/2021</EventDate>
                    <EventPara>Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all help make it better.Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all help make it better.</EventPara>
                </EventInfo>
            </Event>
            <Event flip>
                <EventImg src={ExampleImg}/>
                <EventInfo>
                    <EventTitle>This is a Placeholder Title</EventTitle>
                    <EventDate>12/13/2021</EventDate>
                    <EventPara>Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all help make it better.Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all help make it better.</EventPara>
                </EventInfo>
            </Event>
        </Wrapper>
    </Container>
  </Layout>
)

export default EventsPage
